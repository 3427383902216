// user.ts
import { defineStore } from 'pinia'
import { getLoginUserUsingGet } from '@/services/onestop-bark/userController'

export const useUserRoleStore = defineStore('userRole', {
  state: () => ({
    role: null as string | null
  }),
  actions: {
    async setUserRole(role: string | null) {
      if (role !== null) {
        this.role = role
      }
    }
  }
})

export const useUseridStore = defineStore('userid', {
  state: () => ({
    id: null as string | null
  }),
  actions: {
    async setUserid(id: string | null) {
      if (id !== null) {
        this.id = id
      }
    }
  }
})

export const useUserDataStore = defineStore('userData', {
  state: () => ({
    userData: null as any | null,
  }),
  actions: {
    async fetchAndSetUserData() {
      try {
        const res = await getLoginUserUsingGet();
        if (res.data && res.data.data) {
          this.userData = res.data.data;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
  },
})

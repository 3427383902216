<script setup lang="ts">
import { getAnnouncementVoByIdUsingGet } from '@/services/onestop-bark/announcementController'

import { useRoute } from 'vue-router'
import { type CSSProperties, onMounted, ref } from 'vue'
import Footer from '@/components/Footer.vue'
import Mune_bule from '@/components/Navigation.vue'

const route = useRoute()
const ApplicationDetailsid = route.params.ApplicationDetailsid as string
console.log('公告ID:', ApplicationDetailsid)
// 定义头部区域的样式
const headerStyle: CSSProperties = {
  zIndex: 9999, // 确保始终是最上层
  textAlign: 'center', // 文本居中
  color: '#ffffff', // 文字颜色为白色
  height: '40px', // 高度为40px
  lineHeight: '40px', // 行高与高度一致，确保垂直居中
  background: '#87CEFA', // 渐变天蓝色背景
  padding: 0
}
// 定义底部区域的样式
const footerStyle: CSSProperties = {
  // position: 'fixed', // 添加固定定位
  bottom: 0, // 固定在底部
  left: 0,
  right: 0,
  padding: 0,
  margin: 0,
  textAlign: 'center',
  color: '#fff',
  height: '210px',
  backgroundColor: '#2c4c88'
}
// 定义内容区域的样式
const contentStyle: CSSProperties = {
  // height: '1200px',

  backgroundColor: '#eeeff1', // 背景颜色
  width: '100%',
  margin: '0 auto' //内容区域两边留空，居中
}
// 声明announcement
const announcement = ref<any>(null)

onMounted(async () => {
  try {
    const response = await getAnnouncementVoByIdUsingGet({
      id: parseInt(ApplicationDetailsid)
    })
    if (response.data) {
      announcement.value = response.data
      console.log('公告详情:', announcement.value)
    } else {
      console.error('无法获取公告详情')
    }
  } catch (error) {
    console.error('获取公告详情时出错:', error)
  }
})

// 使用这些数据来填充你的详情页面内容
</script>

<template>
  <!-- 使用 a-space 组件创建垂直间距布局，宽度占满屏幕 -->
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <!-- ants-design 的布局组件，包含头部、内容、底部 -->
    <a-layout>
      <!-- 定义头部样式并插入导航组件 -->
      <a-layout-header :style="headerStyle">
        <Mune_bule></Mune_bule>
      </a-layout-header>
      <!-- 定义内容区域样式 -->
      <a-layout-content :style="contentStyle">
        <div style="height: 100vh; width: 100%">
          <div style="width: 80%; margin: 0 auto">
            <h1 style="font-weight: bold; text-align: center">
              {{ announcement?.data.title }}
            </h1>
            <h4 style="text-align: center; border-bottom: 1px solid #000">
              发布人：<span>{{ announcement?.data.userName || '无' }}</span> ({{
                announcement?.data.role || '无'
              }}) 丨 来源：{{ announcement?.data.receptionRole || '无' }} 丨发布时间：{{
                new Date(announcement?.data.createTime).toLocaleDateString() || '无'
              }}
            </h4>
            <div v-html="announcement?.data.message"></div>
          </div>
        </div>
      </a-layout-content>
      <!-- 定义底部样式并插入页脚组件 -->
      <a-layout-footer :style="footerStyle">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </a-space>
</template>

<style scoped></style>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import timezone from 'dayjs/plugin/timezone';  // 导入时区插件
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  getClassroomVoByIdUsingGet,
  listClassroomVoByPageUsingPost
} from '@/services/onestop-bark/classroomController'
import {
  addYuyuebiaoUsingPost,
  listYuyuebiaoVoByPageUsingPost
} from '@/services/onestop-bark/yuyuebiaoController'

const classrooms = ref<API.ClassroomVO[]>([])
const loading = ref<boolean>(false)
// 增加当前页和每页大小的响应式变量
const current = ref<number>(1)
const pageSize = ref<number>(20)
const totalRecords = ref<number>(0)
const buildingFilter = ref(null); // 楼栋筛选
const statusFilter = ref<boolean>(false);// 初始状态为只看空闲的
const floorFilter = ref(null); // 楼层筛选
const approvalRequiredFilter = ref(false); // 是否需要审批筛选
const requestParams = computed(() => ({
  current: current.value, // 当前页码
  pageSize: pageSize.value // 每页大小
}))

async function fetchClassrooms(): Promise<void> {
  loading.value = true
  try {
    // 打印筛选条件的值，以便于调试
    console.log("Building Filter:", buildingFilter.value);
    console.log("Status Filter:", statusFilter.value);
    console.log("Floor Filter:", floorFilter.value);
    console.log("Approval Required Filter:", approvalRequiredFilter.value);
    const params = {
      ...requestParams.value,
      building: buildingFilter.value !== null ? buildingFilter.value : '', // 如果不是null，则使用其值，否则使用空字符串
      status: statusFilter.value ? '空闲' : '', // 根据statusFilter的布尔值决定是否添加状态筛选条件
      floor: floorFilter.value !== null ? floorFilter.value : '', // 添加楼层筛选条件
      approvalRequired: approvalRequiredFilter.value ? 1 : undefined, // 添加是否需要审批筛选条件
    };
    const response = await listClassroomVoByPageUsingPost(params);
    if (response.data) {
      classrooms.value = response.data.data?.records ?? [];
      totalRecords.value = response.data.data?.total || 0;
      console.log("Fetched records count:", totalRecords.value);
    } else {
      message.error('获取教室列表失败，请稍后重试');
    }
  } catch (error) {
    console.error('获取教室列表时发生错误:', error);
    message.error('网络错误，请检查您的连接');
  } finally {
    loading.value = false;
  }
}
watch(
  [buildingFilter, statusFilter, floorFilter, approvalRequiredFilter],
  () => {
    fetchClassrooms();
  },
  { immediate: true } // 立即执行一次以初始化数据
);

// 修改为fetchClassrooms并传递事件参数
const fetchUsers = (page: number, size: number) => {
  current.value = page
  pageSize.value = size
  fetchClassrooms()
}

const columns = [
  { title: '教室名称', dataIndex: 'name' },
  { title: '所在楼宇', dataIndex: 'building' },
  { title: '楼层', dataIndex: 'floor' },
  { title: '容纳人数', dataIndex: 'capacity' },
  {
    title: '是否需要审批',
    dataIndex: 'approvalRequired'
  },
  {
    title: '状态',
    dataIndex: 'status',
    weight: '100px'
  },

  { title: '使用场景', dataIndex: 'usageScenario' },
  {
    title: '设备配置',
    dataIndex: 'equipment',
    weight: '300px'
  },

  { title: '操作', dataIndex: 'operate' }
]

// 定义一个状态来控制模态框的显示与隐藏
const showModal = ref(false)
const selectedItemData = ref<API.ClassroomVO | null>(null)
const viewDetails = async (recordId: string) => {
  try {
    const selectedItem = await getClassroomVoByIdUsingGet({ id: Number(recordId) })
    if (selectedItem && selectedItem.data && selectedItem.data.data) {
      showModal.value = true
      selectedItemData.value = selectedItem.data.data
    } else {
      message.warning('未找到该教室的详细信息')
    }
  } catch (error) {
    console.error('获取教室详细信息时发生错误:', error)
    message.error('网络错误，请检查您的连接')
  }
}

// 定义关闭模态框的方法
const handleCancel = () => {
  showModal.value = false
}

const applyModalVisible = ref(false)
const Appointmentstartdate = ref<Date | null>(null)
const Appointmentenddate = ref<Date | null>(null)
const usageExplanation = ref<string>('')
// 定义关闭模态框的方法
const handleApplyCancel = () => {
  applyModalVisible.value = false
}



const bookRoom = (roomId: string) => {
  console.log('Selected Room ID:', roomId);
  applyModalVisible.value = true;
  const selectedRoom = classrooms.value.find((room) => room.id === Number(roomId));

  if (selectedRoom) {
    selectedItemData.value = selectedRoom;
    console.log('Selected Item Data:', selectedItemData.value);
  } else {
    message.error('未找到该教室的信息');
  }
};
dayjs.extend(utc);
dayjs.extend(timezone);

// 转换函数
// 格式化时间函数，支持时区
// const formatTime = (date: Date | string) => dayjs(date).tz("Asia/Shanghai").format('YYYY-MM-DD HH:mm:ss');
// 格式化时间函数，手动设置 GMT+8 时区偏移（即 +480 分钟）
const formatTime = (date: Date | string) => {
  return dayjs(date).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'); // 480分钟 = GMT+8
};
const handleSubmit = async () => {
  if (
    !Appointmentstartdate.value ||
    !Appointmentenddate.value ||
    Appointmentenddate.value < Appointmentstartdate.value
  ) {
    message.error('结束时间不能少于开始时间，请检查预约日期');
    return;
  }

  const oneWeekLater = new Date(Appointmentstartdate.value);
  oneWeekLater.setDate(oneWeekLater.getDate() + 7);

  if (Appointmentenddate.value > oneWeekLater) {
    message.error('预约时长不能超过一周');
    return;
  }

  if (!usageExplanation.value) {
    message.error('请填写用途说明');
    return;
  }


  try {
    const response = await addYuyuebiaoUsingPost({
      classroomId: selectedItemData.value?.id,
      kaiShiShiJianTime: formatTime(Appointmentstartdate.value),
      jieShuShiJianTime: formatTime(Appointmentenddate.value), // 转换为字符串
      usageHistory: usageExplanation.value,
    });

    if (response.data.code===0) {
      message.success('预约申请已提交');
      handleApplyCancel();
    } else {
      message.error(`预约申请失败：${response.data.message}`);
    }
  } catch (error) {
    console.error('提交预约申请时发生错误:', error);
    message.error('提交失败，请稍后重试');
  }
};

const appointmentRecords = ref<API.YuyuebiaoVO[]>([]); // 存储预约记录
const showAppointmentsModal = ref(false); // 控制预约详情模态框的显示

const ViewAppointment = async (roomId: string) => {
  try {
    loading.value = true;
    const response = await listYuyuebiaoVoByPageUsingPost({
      classroomId: Number(roomId),
      current: 1, // 页码，默认第 1 页
      pageSize: 10, // 每页记录数
    });

    if (response.data?.data?.records?.length) {
      appointmentRecords.value = response.data.data.records;
      showAppointmentsModal.value = true;
    } else {
      message.info('该教室暂无预约记录');
    }
  } catch (error) {
    console.error('获取预约记录时发生错误:', error);
    message.error('网络错误，请稍后重试');
  } finally {
    loading.value = false;
  }
};

</script>

<template>
  <div style="width: 100%; display: flex; justify-content: center">
    <div style="width: 80%">
      <div style="background: #50fa7b; height: 100px">
        <div style="padding: 10px">
          <span style="font-weight: bold; margin-right: 4px">楼栋筛选：</span>
          <a-select v-model:value="buildingFilter" :defaultValue="null" style="width: 120px">
            <a-select-option :value="null">全部</a-select-option>
            <a-select-option :value="'主楼'">主楼</a-select-option>
            <a-select-option :value="'副楼'">副楼</a-select-option>
            <a-select-option :value="'无上楼'">无上楼</a-select-option>
            <a-select-option :value="'小明楼'">小明楼</a-select-option>
            <a-select-option value="A-敦明楼">A-敦明楼</a-select-option>
            <a-select-option value="B-信义楼">B-信义楼</a-select-option>
            <a-select-option value="C-明志楼">C-明志楼</a-select-option>
            <a-select-option value="D-敏思楼">D-敏思楼</a-select-option>
            <a-select-option value="E-德远楼">E-德远楼</a-select-option>
            <a-select-option value="F-博文楼">F-博文楼</a-select-option>
            <a-select-option value="守正楼">守正楼</a-select-option>
            <a-select-option value="知行楼">知行楼</a-select-option>
            <a-select-option value="行健楼">行健楼</a-select-option>
          </a-select>
          <span style="font-weight: bold; margin-right: 4px">楼层筛选：</span>
          <a-select v-model:value="floorFilter" :defaultValue="null" style="width: 120px">
            <a-select-option :value="null">全部</a-select-option>
            <a-select-option value="1楼">1楼</a-select-option>
            <a-select-option value="2楼">2楼</a-select-option>
            <a-select-option value="3楼">3楼</a-select-option>
            <a-select-option value="4楼">4楼</a-select-option>
            <a-select-option value="5楼">5楼</a-select-option>
            <a-select-option value="6楼">6楼</a-select-option>
            <a-select-option value="7楼">7楼</a-select-option>
            <a-select-option value="8楼">8楼</a-select-option>
            <a-select-option value="9楼">9楼</a-select-option>
            <a-select-option value="10楼">10楼</a-select-option>
            <a-select-option value="11楼">11楼</a-select-option>
            <a-select-option value="12楼">12楼</a-select-option>
          </a-select>
          <span style="font-weight: bold; margin-right: 4px">只看空闲的：</span>
          <a-switch v-model:checked="statusFilter" />
        </div>
      </div>

      <div style="padding-top: 10px">
        <div style="background: #7dc1ed">
          <a-table
            class="ant-table-striped"
            size="middle"
            :columns="columns"
            :data-source="classrooms"
            :pagination="false"
          >
            <template #bodyCell="{ column, record }">
              <!-- 如果是最后一列，添加操作按钮 -->
              <template v-if="column.dataIndex === 'operate'">
                <div class="actions">
                  <a-button size="small" @click.stop="viewDetails(record.id)">教室详情</a-button>
                  <a-button type="primary" size="small" @click.stop="ViewAppointment(record.id)">查看是否有预约</a-button>
                  <a-button type="primary" size="small" @click.stop="bookRoom(record.id)"
                    >申请该教室</a-button
                  >
                </div>
              </template>
              <template v-else>{{
                column.dataIndex === 'capacity'
                  ? `${record[column.dataIndex]}人`
                  : column.dataIndex === 'approvalRequired'
                    ? record[column.dataIndex]
                      ? '是'
                      : '否'
                    : record[column.dataIndex]
              }}</template>
            </template>
          </a-table>
        </div>
      </div>
      <!-- 自定义分页组件 -->
      <div style="text-align: right; margin-top: 16px">
        <a-pagination
          v-model:current="current"
          v-model:pageSize="pageSize"
          :total="totalRecords"
          @change="fetchUsers"
          @showSizeChange="fetchUsers"
        />
      </div>
    </div>
  </div>
  <!-- 添加模态框组件 -->
  <a-modal
    v-model:visible="showModal"
    style="width: 70%"
    centered
    :maskClosable="false"
    cancelText="取消"
    @cancel="handleCancel"
    okText="关闭"
    @ok="handleCancel"
  >
    <a-descriptions :title="`教室：${selectedItemData?.name}`" bordered>
      <a-descriptions-item label="所在楼宇">{{ selectedItemData?.building }}</a-descriptions-item>
      <a-descriptions-item label="楼层">{{ selectedItemData?.floor }}</a-descriptions-item>
      <a-descriptions-item label="容纳人数">{{ selectedItemData?.capacity }}人</a-descriptions-item>
      <a-descriptions-item label="使用场景">{{
        selectedItemData?.usageScenario
      }}</a-descriptions-item>
      <a-descriptions-item label="是否需要审批">
        {{ selectedItemData?.approvalRequired ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否启用">
        {{ selectedItemData?.isActive === 1 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否有投影仪">
        {{ selectedItemData?.hasAudioSystem === 1 ? '有' : '无' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否有音响系统">
        {{ selectedItemData?.hasAirConditioning === 1 ? '有' : '无' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否适合残障学生使用">
        {{ selectedItemData?.accessibility === 1 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="状态" :span="3">
        <a-badge
          :status="
            selectedItemData?.status === '空闲'
              ? 'success'
              : selectedItemData?.status === '使用中'
                ? 'warning'
                : 'error'
          "
          :text="
            selectedItemData?.status === '空闲'
              ? '空闲'
              : selectedItemData?.status === '使用中'
                ? '使用中'
                : '维修中'
          "
        />
      </a-descriptions-item>
      <a-descriptions-item label="设备配置" :span="3">
        {{ selectedItemData?.equipment }}
      </a-descriptions-item>
      <a-descriptions-item label="其他设备" :span="3">
        {{ selectedItemData?.otherEquipment }}
      </a-descriptions-item>
      <a-descriptions-item label="教室的详细描述" :span="3">
        {{ selectedItemData?.description }}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
  <!-- 更新模态框内容 -->
  <a-modal
    v-model:visible="applyModalVisible"
    centered
    :maskClosable="false"
    title="教室预约申请"
    style="width: 20%"
    cancelText="取消"
    @cancel="handleApplyCancel"
    okText="提交预约"
    @ok="handleSubmit"
  >
    <a-form layout="vertical">
      <a-form-item label="预约开始日期">
        <a-date-picker
          v-model:value="Appointmentstartdate"
          show-time
          format="YYYY-MM-DD HH:mm"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="预约结束日期">
        <a-date-picker
          v-model:value="Appointmentenddate"
          show-time
          format="YYYY-MM-DD HH:mm"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="用途说明">
        <a-input v-model:value="usageExplanation" placeholder="请简要说明用途" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="showAppointmentsModal"
    title="预约记录"
    :width="800"
    :maskClosable="false"
    cancelText="关闭"
    okText="确定"
    @cancel="() => { showAppointmentsModal = false; }"
    @ok="() => { showAppointmentsModal = false; }"
  >
    <a-table
      :data-source="appointmentRecords"
      :pagination="false"
      :columns="[
      { title: '预约人', dataIndex: 'userId', key: 'userId' },
      { title: '开始时间', dataIndex: 'kaiShiShiJianTime', key: 'kaiShiShiJianTime' ,render: formatTime},
      { title: '结束时间', dataIndex: 'jieShuShiJianTime', key: 'jieShuShiJianTime',render: formatTime   },
    ]"
      rowKey="id"
    />
  </a-modal>

</template>

<style scoped>
[data-doc-theme='light'] .ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
[data-doc-theme='dark'] .ant-table-striped :deep(.table-striped) td {
  background-color: rgb(29, 29, 29);
}
.actions {
  display: flex;
  gap: 4px;
}
</style>
// @ts-ignore
/* eslint-disable */
import request from '@/requestConfig'; // 导入请求配置

/** addAnnouncement POST /api/announcement/add */
export async function addAnnouncementUsingPost(
  body: API.AnnouncementAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponselong>('/api/announcement/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteAnnouncement POST /api/announcement/delete */
export async function deleteAnnouncementUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/announcement/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** editAnnouncement POST /api/announcement/edit */
export async function editAnnouncementUsingPost(
  body: API.AnnouncementEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/announcement/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getAnnouncementVOById GET /api/announcement/get/vo */
export async function getAnnouncementVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAnnouncementVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseAnnouncementVO>('/api/announcement/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listAnnouncementByPage POST /api/announcement/list/page */
export async function listAnnouncementByPageUsingPost(
  body: API.AnnouncementQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageAnnouncement>('/api/announcement/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listAnnouncementVOByPage POST /api/announcement/list/page/vo */
export async function listAnnouncementVoByPageUsingPost(
  body: API.AnnouncementQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageAnnouncementVO>('/api/announcement/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listMyAnnouncementVOByPage POST /api/announcement/my/list/page/vo */
export async function listMyAnnouncementVoByPageUsingPost(
  body: API.AnnouncementQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageAnnouncementVO>('/api/announcement/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateAnnouncement POST /api/announcement/update */
export async function updateAnnouncementUsingPost(
  body: API.AnnouncementUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseboolean>('/api/announcement/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

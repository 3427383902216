<template>
  <div style="padding: 16px">
    <!-- 添加发布公告按钮 -->
    <a-button type="primary" @click="showAddAnnouncementModal">发布新公告</a-button>
  </div>

  <a-table :columns="columns" :data-source="data" :pagination="false" bordered>
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'id'">
        <a class="custom-link-style">{{ text }}</a>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <a-space size="small">
          <a-button type="primary" ghost size="small" @click="showAddOrEditModal(record)">
            编辑</a-button
          >
          <a-popconfirm title="确认删除该公告吗?" @confirm="handleDelete(record.id)">
            <a-button type="primary" ghost size="small"> 删除</a-button>
          </a-popconfirm>
        </a-space>
      </template>
      <template v-else>
        {{ text }}
      </template>
    </template>
  </a-table>
  <!-- 添加模态框组件 -->
  <a-modal
    v-model:visible="addModalVisible"
    title="发布新公告"
    ok-text="发布"
    cancel-text="取消"
    @ok="handleAddAnnouncement"
    style="width:70%;"
  >
    <a-form :model="newAnnouncement" layout="vertical">
      <a-form-item label="标题" name="title">
        <a-input v-model:value="newAnnouncement.title" />
      </a-form-item>
      <a-form-item label="是否置顶" name="significance">
        <a-radio-group v-model:value="newAnnouncement.significance">
          <a-radio :value="0">不置顶</a-radio>
          <a-radio :value="1">置顶</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="有效期/天" name="deadline">
        <a-input v-model:value="newAnnouncement.deadline" />
      </a-form-item>
      <a-form-item label="发布部门" name="receptionRole">
        <a-input v-model:value="newAnnouncement.receptionRole" />
      </a-form-item>
      <a-form-item label="分类" name="classify">
        <a-input v-model:value="newAnnouncement.classify" />
      </a-form-item>
      <a-form-item label="公告内容" name="message">
        <TextEditor
          v-model="newAnnouncement.message"
          api-key="no-api-key"
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          :init="{
            promotion: false,
            language: 'zh_CN',
            branding:false,
            selector: 'textarea.richTextBox',
               plugins: [
      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
      'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
      'media', 'table', 'emoticons', 'help'
    ],
    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
      'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
      'forecolor backcolor emoticons | help',
    menu: {
      favs: { title: '我的收藏', items: 'code visualaid | searchreplace | emoticons' }
    },
    menubar: 'favs file edit view insert format tools table help',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' }
            ]
          }"
          :rows="4"
          placeholder="请输入公告内容..."
          show-count
          :maxlength="500"
        />
      </a-form-item>
      <!-- 其他表单项可以根据需要添加 -->
    </a-form>
  </a-modal>
  <!-- 在模板中添加编辑模态框 -->
  <a-modal
    v-model:visible="editModalVisible"
    title="编辑公告"
    ok-text="保存"
    cancel-text="取消"
    @ok="handleEditAnnouncement"
    style="width:70%;"
  >
    <!-- 使用相同或类似的表单结构用于编辑 -->
    <!-- 注意使用 record 来初始化表单值 -->
    <a-form :model="editingAnnouncement" layout="vertical">
      <!-- ...表单项定义，与新增模态框类似，但使用 editingAnnouncement 作为 v-model -->
      <a-form-item label="标题" name="title">
        <a-input v-model:value="editingAnnouncement.title" />
      </a-form-item>
      <a-form-item label="是否置顶" name="significance">
        <a-radio-group v-model:value="editingAnnouncement.significance">
          <a-radio :value="0">不置顶</a-radio>
          <a-radio :value="1">置顶</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="发布部门" name="receptionRole">
        <a-input v-model:value="editingAnnouncement.receptionRole" />
      </a-form-item>
      <a-form-item label="有效期/天" name="deadline">
        <a-input v-model:value="editingAnnouncement.deadline" />
      </a-form-item>
      <a-form-item label="分类" name="classify">
        <a-input v-model:value="editingAnnouncement.classify" />
      </a-form-item>
      <a-form-item label="公告内容" name="message">
        <TextEditor
          v-model="editingAnnouncement.message"
          api-key="no-api-key"
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          :init="{
            promotion: false,
            language: 'zh_CN',
            branding:false,
            selector: 'textarea.richTextBox',
                plugins: [
      'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
      'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
      'media', 'table', 'emoticons', 'help'
    ],
    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
      'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
      'forecolor backcolor emoticons | help',
    menu: {
      favs: { title: '我的收藏', items: 'code visualaid | searchreplace | emoticons' }
    },
    menubar: 'favs file edit view insert format tools table help',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' }
            ]
          }"
          :rows="4"
          placeholder="请输入详细公告内容..."
          show-count
          :maxlength="500"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <div style="text-align: right; margin-top: 16px">
    <a-pagination
      v-model:current="requestParams.current"
      v-model:pageSize="requestParams.pageSize"
      :total="total"
      @change="loadAnnouncements"
      @showSizeChange="loadAnnouncements"
    />
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue'
import {
  addAnnouncementUsingPost,
  deleteAnnouncementUsingPost,
  listAnnouncementByPageUsingPost,
  updateAnnouncementUsingPost
} from '@/services/onestop-bark/announcementController'
import { message } from 'ant-design-vue'

const requestParams = reactive({
  current: 1,
  pageSize: 10, // 默认每页显示10条
  sortField: 'id',
  sortOrder: 'desc'
})
const total = ref<number>(0)
const loadAnnouncements = async () => {
  try {
    requestParams.current = Number(requestParams.current); // 确保转换为数字类型
    requestParams.pageSize = Number(requestParams.pageSize);
    const response = await listAnnouncementByPageUsingPost(requestParams)
    data.value =
      (response.data?.data?.records?.map((record) => ({
        ...record,
        createTime: record.createTime ? new Date(record.createTime).toLocaleDateString() : '',
        updateTime: record.updateTime ? new Date(record.updateTime).toLocaleDateString() : ''
      })) as []) ?? []
    total.value = response.data?.data?.total || 0; // 更新总记录数
    console.log(total)
  } catch (error) {
    message.error('重新加载公告列表失败')
  }
}

onMounted(loadAnnouncements)
// 定义控制模态框显示的变量
const addModalVisible = ref(false)

// 新公告的数据模型
const newAnnouncement = ref({
  title: '',
  message: '',
  significance: 0, // Initialize with a default value that matches the expected type
  receptionRole: '',
  classify: '',
  deadline: 0
  // 其他字段...
})

// 显示模态框的方法
const showAddAnnouncementModal = () => {
  addModalVisible.value = true
}

// 修改模态框的确定按钮回调为更新操作
const handleAddAnnouncement = () => {
  // 添加公告的逻辑保持不变
  addAnnouncementUsingPost(newAnnouncement.value)
    .then(() => {
      addModalVisible.value = false
      loadAnnouncements()
      message.success('公告发布成功')
    })
    .catch((error) => {
      message.error('发布公告失败')
    })
}

// 控制编辑模态框显示的变量
const editModalVisible = ref(false)

// 当前正在编辑的公告数据
const editingAnnouncement = ref({} as any)

// 显示编辑模态框并预填表单
const showAddOrEditModal = (record: any) => {
  // 如果是编辑，则设置编辑状态和数据
  if (record.id) {
    editModalVisible.value = true
    editingAnnouncement.value = { ...record }
  } else {
    // 如果是新增，则保持原逻辑
    addModalVisible.value = true
  }
}

// 处理编辑公告的逻辑
const handleEditAnnouncement = () => {
  // 确保有id并且其他字段有值才进行更新请求，避免提交空数据
  if (
    editingAnnouncement.value.id &&
    Object.keys(editingAnnouncement.value).some(
      (key) => key !== 'id' && editingAnnouncement.value[key]
    )
  ) {
    updateAnnouncementUsingPost({ id: editingAnnouncement.value.id, ...editingAnnouncement.value })
      .then(() => {
        editModalVisible.value = false
        loadAnnouncements()
        message.success('公告更新成功')
      })
      .catch((error) => {
        message.error('更新公告失败')
      })
  } else {
    message.warning('请填写有效的公告信息后再提交！')
  }
}

const data = ref<[]>([])
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true
  },
  {
    title: '公告内容',
    dataIndex: 'message',
    key: 'message',
    ellipsis: true
  },
  {
    title: '是否置顶',
    dataIndex: 'significance',
    key: 'significance',
    ellipsis: true
  },
  {
    title: '发布人',
    dataIndex: 'userName',
    key: 'userName',
    ellipsis: true
  },
  {
    title: '发布的部门',
    dataIndex: 'receptionRole',
    key: 'receptionRole',
    ellipsis: true
  },
  {
    title: '分类',
    dataIndex: 'classify',
    key: 'classify',
    ellipsis: true
  },
  {
    title: '有效期/天',
    dataIndex: 'deadline',
    key: 'deadline',
    ellipsis: true
  },
  {
    title: '发布时间',
    dataIndex: 'createTime',
    key: 'createTime',
    ellipsis: true
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    ellipsis: true
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

const handleDelete = async (id: string) => {
  try {
    await deleteAnnouncementUsingPost({ id: parseInt(id) })
    // 删除后可以刷新列表数据
    await loadAnnouncements()
    message.success('删除成功')
  } catch (error) {
    message.error('删除公告失败')
  }
}
</script>
<style scoped></style>

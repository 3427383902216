// @ts-ignore
/* eslint-disable */
import request from '@/requestConfig'

/** addYuyuebiao POST /api/yuyuebiao/add */
export async function addYuyuebiaoUsingPost(
  body: API.YuyuebiaoAddRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponselong>('/api/yuyuebiao/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** deleteYuyuebiao POST /api/yuyuebiao/delete */
export async function deleteYuyuebiaoUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/yuyuebiao/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** editYuyuebiao POST /api/yuyuebiao/edit */
export async function editYuyuebiaoUsingPost(
  body: API.YuyuebiaoEditRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/yuyuebiao/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** getYuyuebiaoVOById GET /api/yuyuebiao/get/vo */
export async function getYuyuebiaoVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getYuyuebiaoVOByIdUsingGETParams,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseYuyuebiaoVO>('/api/yuyuebiao/get/vo', {
    method: 'GET',
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** listYuyuebiaoByPage POST /api/yuyuebiao/list/page */
export async function listYuyuebiaoByPageUsingPost(
  body: API.YuyuebiaoQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageYuyuebiao>('/api/yuyuebiao/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** listYuyuebiaoVOByPage POST /api/yuyuebiao/list/page/vo */
export async function listYuyuebiaoVoByPageUsingPost(
  body: API.YuyuebiaoQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageYuyuebiaoVO>('/api/yuyuebiao/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** listMyYuyuebiaoVOByPage POST /api/yuyuebiao/my/list/page/vo */
export async function listMyYuyuebiaoVoByPageUsingPost(
  body: API.YuyuebiaoQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageYuyuebiaoVO>('/api/yuyuebiao/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** updateYuyuebiao POST /api/yuyuebiao/update */
export async function updateYuyuebiaoUsingPost(
  body: API.YuyuebiaoUpdateRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/yuyuebiao/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

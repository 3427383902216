import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import admin from '@/views/admin.vue'
import { getLoginUserUsingGet } from '@/services/onestop-bark/userController'
import Department_management from '@/components/admin/Department_management.vue'
import not_found from '@/views/404.vue'
import Template from '@/views/TemplateComponent.vue'
import Service_Details from '@/components/admin/Service_Details.vue'
// 在你获取到res的逻辑之后
import { useUserDataStore, useUseridStore, useUserRoleStore } from '@/stores/user'
import Password_Reset from '@/views/Password_Reset.vue'
import JSONBig from 'json-bigint'
import Details from '@/views/Details.vue'
import User from '@/views/User.vue'
import AnnouncementDetails from '@/components/user/MyNews/Announcement_Details.vue'
import { message } from 'ant-design-vue'
import ClassroomReservation from '@/views/ClassroomReservation.vue'
import ApplicationDetails from '@/components/user/MyNews/ApplicationDetails.vue'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: '登录' },

    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: '首页' },
    },
    {
      path: '/admin',
      name: 'admin',
      component: admin,
      meta: { title: '管理页' },
    },
    {
      path: '/404',
      name: '404',
      component: not_found
    },
    {
      path: '/Template/:title',
      name: 'Template',
      component: Template,
      props: true, // 将路由参数传递给组件
    },
    {
      path: '/Service',
      name: 'Service',
      component: Service_Details,
    },
    {
      path: '/Password_Reset',
      name: 'Password_Reset',
      component: Password_Reset,
    },
    {
      path: '/Details',
      name: 'Details',
      component: Details,
    },
    {
      path: '/User',
      name: 'User',
      component: User,
      meta: { title: '个人中心' },
    },
    {
      path: '/Announcement/:announcementId',
      name: 'AnnouncementDetails',
      component: AnnouncementDetails,
      props: true, // 这样可以直接从 $route.params 获取参数
    },
    //我的申请
    {
      path: '/ApplicationDetails/:ApplicationDetailsid',
      name: 'ApplicationDetails',
      component: ApplicationDetails,
      props: true, // 这样可以直接从 $route.params 获取参数
    },
    {
      path: '/ClassroomReservation',//教室预约
      name: 'ClassroomReservation',
      component: ClassroomReservation,
      meta: { title: '教室申请-老师专用' },
    },
  ]
})




// 导航守卫
// 登录是 false
router.beforeEach(async (to, from, next) => {
  const userDataStore = useUserDataStore();
  // 检查是否已经登录（这里简单假设如果userDataStore.userData有值就是已登录）
  try {
    const res = await getLoginUserUsingGet();
    if (res.data?.data?.userRole) {
      const userRoleStore = useUserRoleStore();
      await userRoleStore.setUserRole(res.data.data.userRole);
      const userIdStore = useUseridStore();
      await userIdStore.setUserid(JSONBig.stringify(res.data.data.id));
      userDataStore.userData = res.data.data;

      // 添加对admin页面访问权限的判断
      if (to.path === '/admin' && res.data.data.userRole !== 'admin') {
        // 如果用户试图访问admin页面但不是admin角色，则重定向
        next({ path: '/', query: { redirect: to.fullPath } }); // 假设/noPermission是一个无权限提示页面，并携带原本要访问的路径以便后续操作
        message.error(`你无权访问该页面`)
      } else {
        next();
      }
    } else if (!res.data?.data && to.path !== '/login') {
      next({ path: '/login' });
    } else if (to.path === '/login' && res.data?.data) {
      next({ path: '/' });
    } else {
      console.log('成功进入');
      next();
    }
  } catch (error) {
    console.error('Error fetching login status', error);
    next({ path: '/login' });
  }
});

export default router

<template>
  <a-layout>
    <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
      :style="{ height: '105vh' }"
    >
      <div class="logo" style="text-align: center; font-weight: bold; padding: 16px">
        <h1
          style="
            background-image: linear-gradient(to right, #add8e6, #87cefa);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
          "
        >
          一站式综合管理平台
        </h1>
      </div>
      <a-menu v-model:selectedKeys="selectedKeys"
              theme="dark" mode="inline" @click="handleClick">
        <a-menu-item key="1">
          <template #icon>
            <home-outlined />
          </template>
          首页
        </a-menu-item>
        <a-menu-item key="2">
          <template #icon>
            <user-outlined />
          </template>
          用户信息管理
        </a-menu-item>
        <a-sub-menu key="sub6">
          <template #title>
            <setting-outlined />
            <span class="nav-text">模块信息管理</span>
          </template>
          <a-menu-item key="6-2">模块管理</a-menu-item>
          <a-menu-item key="6-3">信息管理</a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub4">
          <template #title>
            <TeamOutlined/>
            <span class="nav-text">老师服务管理</span>
          </template>
          <a-menu-item key="4-1">教室申请管理</a-menu-item>
          <a-menu-item key="4-2">学生服务管理</a-menu-item>
          <a-menu-item key="4-3">游客服务管理</a-menu-item>
          <a-menu-item key="4-4">教师服务管理</a-menu-item>
          <a-menu-item key="4-5">学生服务管理</a-menu-item>
          <a-menu-item key="4-6">游客服务管理</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub5">
          <template #title>
            <star-outlined />
            <span class="nav-text">学生服务管理</span>
          </template>
          <a-menu-item key="5-1">热门服务管理</a-menu-item>
          <a-menu-item key="5-2">推荐服务管理</a-menu-item>
          <a-menu-item key="5-3">最新服务管理</a-menu-item>
          <a-menu-item key="5-4">热门服务管理</a-menu-item>
          <a-menu-item key="5-5">推荐服务管理</a-menu-item>
          <a-menu-item key="5-6">最新服务管理</a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub7">
          <template #title>
            <file-done-outlined />
            <span class="nav-text">游客服务管理</span>
          </template>
          <a-menu-item key="7-1">代号：天地巨力</a-menu-item>
          <a-menu-item key="7-2">Sub nav 7-2</a-menu-item>
          <a-menu-item key="7-3">代号：天地巨力</a-menu-item>
          <a-menu-item key="7-4">Sub nav 7-4</a-menu-item>
          <a-menu-item key="7-5">代号：天地巨力</a-menu-item>
          <a-menu-item key="7-6">Sub nav 7-6</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub10">
          <template #title>
            <DatabaseOutlined/>
            <span class="nav-text">开发人员专用</span>
          </template>
          <a-menu-item key="5-1">热门算法机制管理</a-menu-item>
          <a-menu-item key="5-2">玖月图床管理</a-menu-item>
          <a-menu-item key="5-3">平台公告管理</a-menu-item>
          <a-menu-item key="5-4">热门服务管理</a-menu-item>
          <a-menu-item key="5-5">推荐服务管理</a-menu-item>
          <a-menu-item key="5-6">最新服务管理</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="3">
          <template #icon>
            <appstore-outlined />
          </template>
          用户反馈管理
        </a-menu-item>
        <!--        <a-sub-menu key="sub8">-->
        <!--          <template #title>-->
        <!--            <sound-outlined />-->
        <!--            <span class="nav-text">nav 8</span>-->
        <!--          </template>-->
        <!--          <a-menu-item key="8-1">Sub nav 8-1</a-menu-item>-->
        <!--          <a-menu-item key="8-2">Sub nav 8-2</a-menu-item>-->
        <!--          <a-menu-item key="8-3">Sub nav 8-3</a-menu-item>-->
        <!--        </a-sub-menu>-->

        <a-menu-item key="9">
          <template #icon>
            <logout-outlined />
          </template>
          Exit
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        :style="{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'center' }"
      >
        <div>管理平台</div>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <div
          :key="selectedKeys[0]"
          :style="{ padding: '24px', background: '#fff', minHeight: '360px' }"
        >
          {{ getSubContent(selectedKeys[0]) }}

          <!-- 新增异步组件显示逻辑 -->
          <component
            v-if="currentComponent !== null"
            :is="currentComponent"
            :key="'async-' + selectedKeys[0]"
            :style="{ marginTop: '24px' }"
          />
        </div>
      </a-layout-content>
      <!--      <a-layout-footer style="text-align: center">-->
      <!--        高校一站式服务管理平台 ©2025 玖丨无极~开发团队<a-divider type="vertical" />-->
      <!--        <a href="https://www.antdv.com/docs/vue/introduce-cn/">无极</a>-->
      <!--      </a-layout-footer>-->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import { ref, defineAsyncComponent, computed, reactive } from 'vue'
import {
  HomeOutlined,
  UserOutlined,
  AppstoreOutlined,
  StarOutlined,
  SettingOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined
} from '@ant-design/icons-vue'
import Service_Details from '@/components/admin/Service_Details.vue'
import { useRouter } from 'vue-router'
import Announcement_Management from '@/components/admin/Announcement_Management.vue'
import ClassroomManagement from '@/components/admin/ClassroomManagement.vue' // 引入 useRouter 函数
// 异步组件定义
const Index = defineAsyncComponent(() => import('../components/admin/index.vue'))
const User = defineAsyncComponent(() => import('../components/admin/User.vue'))
const Department_management = defineAsyncComponent(
  () => import('../components/admin/Department_management.vue')
)
const UserManager = defineAsyncComponent(() => import('../components/admin/User.vue'))
const RoleSetting = defineAsyncComponent(() => import('../components/admin/User.vue'))
const Image_hosting = defineAsyncComponent(() => import('../components/admin/Image_hosting.vue'))
const Feedback_Management = defineAsyncComponent(() => import('../components/admin/Feedback_Management.vue'))
const router = useRouter() // 创建 router 实例
const onCollapse = (collapsed: boolean, type: string) => {
  collapsed ? console.log('侧边栏已折叠', type) : console.log('侧边栏已展开', type)
}

const onBreakpoint = (broken: boolean) => {
  broken ? console.info('已触发表单断点，侧边栏折叠') : console.info('已触发表单断点，侧边栏展开')
}

const selectedKeys = ref<string[]>(['1'])

const handleClick = (event: any) => {
  if (event.key === '9') {
    // 假设 '9' 是 "Exit" 菜单项的 key
    router.push('/') // 使用 router.push 进行到首页的跳转
  } else {
    if (event.target instanceof HTMLLIElement) {
      const { key } = event // 从事件参数直接获取 key
      console.log('Clicked menu item key:', key)
      selectedKeys.value = [key]
    }
  }
}

const getSubContent = (key: string) => {
  // // 原有内容返回逻辑保持不变
  // switch (key) {
  //   case '1':
  //     return 'User.vue'
  //   case '2':
  //     return '产品介绍'
  //   case '3':
  //     return '关于我们'
  //   case '4-1':
  //     return '用户管理'
  //   case '4-2':
  //     return '角色设置'
  //   default:
  //     return '请从菜单选择'
  // }
}

// 新增计算属性来决定当前要显示的组件
const currentComponent = computed(() => {
  switch (selectedKeys.value[0]) {
    case '1':
      return Index
    case '2':
      return User
    case '3':
      return Feedback_Management
    case '4-1':
      return ClassroomManagement
    case '4-2':
      return RoleSetting
    case '4-3':
      return Index
    case '4-4':
      return Index
    case '4-5':
      return Index
    case '4-6':
      return Index

    case '5-1':
      return Index
    case '5-2':
      return Image_hosting
    case '5-3':
      return Announcement_Management
    case '5-4':
      return Index
    case '5-5':
      return Index
    case '5-6':
      return Index

    case '6-2':
      return Department_management
    case '6-3':
      return Service_Details
    case '7-1':
      return Index
    case '7-2':
      return Index
    case '7-3':
      return Index
    case '7-4':
      return Index
    case '7-5':
      return Index
    case '7-6':
      return Index
    default:
      return null
  }
})

</script>

<style scoped>
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout-sub-header-background {
  background: #141414;
}
</style>

// @ts-ignore
/* eslint-disable */
import request from '@/requestConfig'

/** addClassroom POST /api/classroom/add */
export async function addClassroomUsingPost(
  body: API.ClassroomAddRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponselong>('/api/classroom/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** deleteClassroom POST /api/classroom/delete */
export async function deleteClassroomUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/classroom/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** editClassroom POST /api/classroom/edit */
export async function editClassroomUsingPost(
  body: API.ClassroomEditRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/classroom/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** getClassroomVOById GET /api/classroom/get/vo */
export async function getClassroomVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getClassroomVOByIdUsingGETParams,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseClassroomVO>('/api/classroom/get/vo', {
    method: 'GET',
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** listClassroomByPage POST /api/classroom/list/page */
export async function listClassroomByPageUsingPost(
  body: API.ClassroomQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageClassroom>('/api/classroom/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** listClassroomVOByPage POST /api/classroom/list/page/vo */
export async function listClassroomVoByPageUsingPost(
  body: API.ClassroomQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageClassroomVO>('/api/classroom/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** listMyClassroomVOByPage POST /api/classroom/my/list/page/vo */
export async function listMyClassroomVoByPageUsingPost(
  body: API.ClassroomQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageClassroomVO>('/api/classroom/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** updateClassroom POST /api/classroom/update */
export async function updateClassroomUsingPost(
  body: API.ClassroomUpdateRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseboolean>('/api/classroom/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

<template>
  <!-- 使用 a-space 组件创建垂直间距布局，宽度占满屏幕 -->
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <!-- ants-design 的布局组件，包含头部、内容、底部 -->
    <a-layout>
      <!-- 定义头部样式并插入导航组件 -->
      <a-layout-header :style="headerStyle">
        <Mune_bule></Mune_bule>
      </a-layout-header>
      <!-- 定义内容区域样式 -->
      <a-layout-content :style="contentStyle">
        <div
          style="width: 100%;height: 100vh;"
        >
          <ClassroomReservationS></ClassroomReservationS>


        </div>
      </a-layout-content>
      <!-- 定义底部样式并插入页脚组件 -->

    </a-layout>
  </a-space>
</template>

<script lang="ts" setup>
import { type CSSProperties, onMounted, ref } from 'vue'
// 导入自定义导航组件
import Mune_bule from '@/components/Navigation.vue'
// 导入自定义页脚组件
import ClassroomReservationS from '@/components/ClassroomReservation/ClassroomReservationS.vue'
onMounted(() => {
  window.scrollTo(0, 0);
});
// 定义头部区域的样式
const headerStyle: CSSProperties = {
  position: 'sticky', // 添加粘性定位
  top: 0, // 始终置顶
  zIndex: 9999, // 确保始终是最上层
  textAlign: 'center', // 文本居中
  color: '#ffffff', // 文字颜色为白色
  height: '40px', // 高度为40px
  lineHeight: '40px', // 行高与高度一致，确保垂直居中
  background: '#87CEFA', // 渐变天蓝色背景
  padding: 0
}
// 定义内容区域的样式
const contentStyle: CSSProperties = {
  backgroundColor: '#eeeff1', // 背景颜色
  width: '100%',
  margin: '0 auto' //内容区域两边留空，居中
}

</script>

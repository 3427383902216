<script lang="ts" setup>
import Mune_bule from '@/components/Navigation.vue'
import { computed, type CSSProperties, defineAsyncComponent, h, ref } from 'vue'
import { useUserDataStore } from '@/stores/user'
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons-vue'
import type { MenuProps } from 'ant-design-vue'
// 使用解构来获取store的属性，这将提供更好的类型提示
const { userData } = useUserDataStore()

const headerStyle: CSSProperties = {
  textAlign: 'center',
  color: '#ffffff',
  height: '40px',
  lineHeight: '40px',
  background: '#87CEFA',
  padding: 0
}

const contentStyle: CSSProperties = {
  color: '#1d8bcf',
  backgroundColor: '#eeeff1',
  width: '100%',
  margin: '0 auto'
}
// 定义各个内容组件
const PersonalData = defineAsyncComponent(() => import('@/components/user/PersonalData.vue'))
const MyNews = defineAsyncComponent(() => import('@/components/user/MyNews.vue'))
const ServiceRecords = defineAsyncComponent(() => import('@/components/user/ServiceRecords.vue'))
const Feedback = defineAsyncComponent(() => import('@/components/user/Feedback.vue'))
const current = ref<string[]>(['My_News'])
const items = ref<MenuProps['items']>([
  {
    key: 'My_News',
    icon: () => h(AppstoreOutlined),
    label: '信息中心',
    title: '信息中心'
  },
  {
    key: 'personal_data',
    icon: () => h(MailOutlined),
    label: '个人资料',
    title: '个人资料'
  },

  {
    key: 'Service_Records',
    icon: () => h(SettingOutlined),
    label: '账号使用日志',
    title: '账号使用日志'
  },
  {
    key: 'Feedback',
    icon: () => h(SettingOutlined),
    label: '意见反馈',
    title: '意见反馈'
  }
])
// 计算属性，根据current的值返回对应的组件
const currentContent = computed(() => {
  switch (current.value[0]) {
    case 'personal_data':
      return PersonalData
    case 'My_News':
      return MyNews
    case 'Service_Records':
      return ServiceRecords
    case 'Feedback':
      return Feedback
    default:
      return PersonalData // 默认情况下显示个人信息
  }
})
</script>

<template>
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <a-layout>
      <a-layout-header :style="headerStyle">
        <Mune_bule />
      </a-layout-header>

      <a-layout-content :style="contentStyle">
        <div class="whole">
          <div class="contents">
            <div class="head">
              <div class="profile" style="height: 115px; background: transparent"></div>
              <div class="profile_picture" style="background: transparent">
                <div
                  style="display: flex; align-items: center; margin-left: 16px; margin-bottom: 16px"
                >
                  <a-avatar :size="64">
                    <template #icon><img :src="userData.userAvatar" /> </template>
                  </a-avatar>
                  <div style="margin: 0 0 0 16px">
                    <h1 style="margin: 0; color: #ffffff">{{ userData.userName }}</h1>
                    <h3 style="margin: 0; color: #ffffff">ID:{{ userData.id }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="Navigation_bar" style="margin-bottom: 16px">
              <div>
                <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
              </div>
            </div>
            <div class="Content_Blocks">
              <component :is="currentContent" />
            </div>
          </div>
        </div>
      </a-layout-content>

      <!--      <a-layout-footer :style="footerStyle">-->
      <!--        <Footer />-->
      <!--      </a-layout-footer>-->
    </a-layout>
  </a-space>
</template>
<style scoped>
.whole {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.contents {
  width: 80%;
}
.head {
  background: #2edfa3;
  height: 200px;
  background-image: url('../assets/grbj-3.png');
}
.Navigation_bar {
  height: 40px;
  border-bottom-left-radius: 4px; /* 设置左下角的弧度 */
  border-bottom-right-radius: 4px; /* 设置右下角的弧度 */
}

.Content_Blocks {
  background: #ffffff;
  height: 100vh;
  border-radius: 4px; /* 添加这行代码来设置四个角的弧度 */
}
</style>

<script setup lang="ts">
// 定义各个内容组件
import { computed, defineAsyncComponent, h, ref } from 'vue'
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons-vue'
import type { MenuProps } from 'ant-design-vue'

const PersonalData = defineAsyncComponent(() => import('@/components/admin/Teacher_Services/ApplicationManagement.vue'))
const Feedback = defineAsyncComponent(() => import('@/components/admin/Teacher_Services/ClassroomManagements.vue'))
const current = ref<string[]>(['1'])
const items = ref<MenuProps['items']>([
  {
    key: '1',
    icon: () => h(AppstoreOutlined),
    label: '教室信息管理',
    title: '教室信息管理'
  },
  {
    key: '2',
    icon: () => h(MailOutlined),
    label: '申请管理',
    title: '申请管理'
  },
])
// 计算属性，根据current的值返回对应的组件
const currentContent = computed(() => {
  switch (current.value[0]) {
    case '2':
      return PersonalData
    case '1':
      return Feedback
    default:
      return PersonalData // 默认情况下显示个人信息
  }
})
</script>

<template>
  <div>
    <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
  </div>

  <div class="Content_Blocks">
    <component :is="currentContent" />
  </div>
</template>

<style scoped>

</style>